import React, { useState } from "react";

const InputBox = ({ row, onHandleCheckedItems }: any) => {
  // console.log("row", row);
  const [bChecked, setChecked] = useState(true);

  const checkHandler = ({ target }: any) => {
    setChecked(!bChecked);
    onHandleCheckedItems(row, target.checked);
  };

  return (
    <>
      <input type="checkbox" checked={bChecked} onChange={checkHandler} />
    </>
  );
};

export default InputBox;

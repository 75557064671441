import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import * as venn from "@upsetjs/venn.js";
import useWindowDimensions from "./hooks/useWindowDimensions";
import InputBox from "./InputBox";
import { useRef } from "react";

function App() {
  const { height, width } = useWindowDimensions();

  const [title, setTitle] = useState("");
  const [textA, setTextA] = useState("");
  const [textB, setTextB] = useState("");
  const [textC, setTextC] = useState("");
  const [textAB, setTextAB] = useState("");
  const [textAC, setTextAC] = useState("");
  const [textBC, setTextBC] = useState("");
  const [textABC, setTextABC] = useState("");
  const [textComp, setTextComp] = useState("");

  const inputA = useRef(null);
  const inputB = useRef(null);
  const inputC = useRef(null);
  const inputAB = useRef(null);
  const inputAC = useRef(null);
  const inputBC = useRef(null);
  const inputABC = useRef(null);

  // C를 포함할지 말지 선택
  // 단 버그가 있음. 껏다가 켜면 색상값이 틀어지므로, 사실상 저장 후 다시 refrash해서 써야 함..
  const [checkedItems, setCheckedItems] = useState(new Set([3]));

  // C를 제외할 때, 다시 랜더링이 필요하여 만든 상태
  // true 가 되는 순간 다이어그램 div를 제거하고, 다시 false가 되면서 재출력한다.
  const [refrash, setRefrash] = useState(false);
  useEffect(() => {
    if (refrash) {
      setRefrash(false);
    }
  }, [refrash]);

  const onHandleCheckedItems = (row: number, checked: boolean) => {
    if (checked) {
      checkedItems.add(row);
    } else if (!checked && checkedItems.has(row)) {
      checkedItems.delete(row);
    }
    setCheckedItems(checkedItems);
    setRefrash(true);
    // console.log(row, checkedItems);
  };

  const [isSaving, setIsSaving] = useState(false);

  const [sets, setSets] = useState([
    { sets: ["A"], size: 1000, label: textA },
    { sets: ["B"], size: 1000, label: textB },
    { sets: ["C"], size: 1000, label: textC },
    { sets: ["A", "B"], size: 300, label: textAB },
    { sets: ["C", "A"], size: 300, label: textAC },
    { sets: ["C", "B"], size: 300, label: textBC },
    { sets: ["C", "A", "B"], size: 100, label: textABC },
  ]);

  //저장 key값은 "저장공간-field" 패턴으로 구성
  //1-a, 1-b, 1-c, 1-ab, 1-ac, 1-bc, 1-abc
  //2-a, 2-b, 2-c, 2-ab, 2-ac, 2-bc, 2-abc
  const saveTexts = (n: number) => {
    localStorage.setItem(`${n}-t`, title);
    localStorage.setItem(`${n}-a`, textA);
    localStorage.setItem(`${n}-b`, textB);
    localStorage.setItem(`${n}-c`, textC);
    localStorage.setItem(`${n}-ab`, textAB);
    localStorage.setItem(`${n}-ac`, textAC);
    localStorage.setItem(`${n}-bc`, textBC);
    localStorage.setItem(`${n}-abc`, textABC);
    localStorage.setItem(`${n}-comp`, textComp);
    setIsSaving(true);
  };

  //저장 직후 title 이 보이도록 갱신
  useEffect(() => {
    if (isSaving) {
      setIsSaving(false);
      (window as any).gtag("event", `save`, {
        event_label: "save item",
      });
    }
  }, [isSaving]);

  const loadTexts = (n: number) => {
    setTitle(localStorage.getItem(`${n}-t`) || "");
    setTextA(localStorage.getItem(`${n}-a`) || "");
    setTextB(localStorage.getItem(`${n}-b`) || "");
    setTextC(localStorage.getItem(`${n}-c`) || "");
    setTextAB(localStorage.getItem(`${n}-ab`) || "");
    setTextAC(localStorage.getItem(`${n}-ac`) || "");
    setTextBC(localStorage.getItem(`${n}-bc`) || "");
    setTextABC(localStorage.getItem(`${n}-abc`) || "");
    setTextComp(localStorage.getItem(`${n}-comp`) || "");

    (window as any).gtag("event", `load`, {
      event_label: `load item ${n}`,
    });
  };

  const initTexts = () => {
    setTitle("");
    setTextA("");
    setTextB("");
    setTextC("");
    setTextAB("");
    setTextAC("");
    setTextBC("");
    setTextABC("");
    setTextComp("");
    (window as any).gtag("event", `init`, {
      event_label: `init`,
    });
  };

  const getSize = (text: string) => {
    const size = text.length * 70;
    // return size > 1000 ? size : 1000;
    return 1000;
  };

  const getMinorSize = (text: string) => {
    const size = text.length * 50;
    // return size > 300 ? size : 300;
    return text.length > 0 ? 300 : 0;
    // return 300;
  };

  const textChange = (text: string, set: any) => {
    set(text);
  };

  useEffect(() => {
    // 벤다이어그램에 들어갈 글자를 갱신하는 함수
    // C가 포함되느냐 아니냐에 따라 set의 형태가 변한다.
    let newVen = [
      { sets: ["A"], size: getSize(textA), label: textA },
      { sets: ["B"], size: getSize(textB), label: textB },
      { sets: ["A", "B"], size: getMinorSize(textAB), label: textAB },
    ];
    if (checkedItems.has(3)) {
      newVen = [
        ...newVen,
        { sets: ["C"], size: getSize(textC), label: textC },
        { sets: ["C", "A"], size: getMinorSize(textAC), label: textAC },
        { sets: ["C", "B"], size: getMinorSize(textBC), label: textBC },
        { sets: ["C", "A", "B"], size: getMinorSize(textABC), label: textABC },
      ];
    }
    setSets(newVen);
  }, [textA, textB, textC, textAB, textAC, textBC, textABC, refrash]);

  useEffect(() => {
    // 벤다이어그램 그리는 함수, 각종 설정 가능
    let chart: any = venn.VennDiagram({
      // colourScheme: ["rgb(235, 237, 238)", "#F26250"],
      // colourScheme: ["blue", "green", "red"],
      // textFill: "#000",
    });

    // 모바일의 경우, 키보드로 인해 height 가 반토막이 나는 현상을 막기 위한 코드
    let isMobile = false; //initiate as false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
    const w: number = width || 500;
    let h: number = height || 500;
    if (!isMobile) {
      h = h / 2;
    }
    chart.width(w - w * 0.1).height(h);
    d3.select("#venn").datum(sets).call(chart);
    d3.selectAll("#venn .venn-circle").on("click", function () {
      const nodeId = (this as any).attributes["data-venn-sets"].value;
      switch (nodeId) {
        case "A":
          (inputA as any).current.focus();
          break;
        case "B":
          (inputB as any).current.focus();
          break;
        case "C":
          (inputC as any).current.focus();
          break;
      }
    });
    d3.selectAll("#venn .venn-intersection").on("click", function () {
      const nodeId = (this as any).attributes["data-venn-sets"].value;
      switch (nodeId) {
        case "C_A":
          (inputAC as any).current.focus();
          break;
        case "C_B":
          (inputBC as any).current.focus();
          break;
        case "A_B":
          (inputAB as any).current.focus();
          break;
        case "C_A_B":
          (inputABC as any).current.focus();
          break;
      }
    });
  }, [sets]);

  const loop = [1, 2, 3, 4, 5];

  return (
    <div id="container" className="justify-center items-center">
      <div
        id="venn-title"
        className="bg-black p-4 font-bold text-white text-center justify-center items-center"
      >
        Miracle Venn Diagram Editor V1.1
      </div>

      <div
        id="venn-title"
        className="bg-gray-200 p-4 font-bold text-center justify-center items-center"
      >
        <div>
          <div className="flex-row justify-center items-center">
            {loop.map((n) => (
              <div key={n} className="flex justify-center items-center">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4 my-1"
                  onClick={() => saveTexts(n)}
                >
                  save {n}
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mx-4 my-1"
                  onClick={() => loadTexts(n)}
                >
                  load {n}
                </button>
                <div className="w-24">{localStorage.getItem(`${n}-t`)}</div>
              </div>
            ))}
            <button
              className="bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-4"
              onClick={initTexts}
            >
              init Texts
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Title</span>
          </label>
          <input
            id="text-t"
            value={title}
            className="w-1/2"
            onChange={(e) => textChange(e.target.value, setTitle)}
          ></input>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set A</span>
          </label>
          <textarea
            id="text-a"
            value={textA}
            className="w-1/2"
            ref={inputA}
            onChange={(e) => textChange(e.target.value, setTextA)}
          ></textarea>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set B</span>
          </label>
          <textarea
            id="text-b"
            value={textB}
            ref={inputB}
            className="w-1/2"
            onChange={(e) => textChange(e.target.value, setTextB)}
          ></textarea>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <InputBox row={3} onHandleCheckedItems={onHandleCheckedItems} />
          <label className="w-1/2">
            <span className="font-bold">Set C</span>
          </label>
          <textarea
            id="text-b"
            value={textC}
            ref={inputC}
            className="w-1/2"
            disabled={!checkedItems.has(3)}
            onChange={(e) => textChange(e.target.value, setTextC)}
          ></textarea>
        </div>

        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set A ∩ B</span>
          </label>
          <textarea
            id="text-b"
            value={textAB}
            ref={inputAB}
            className="w-1/2"
            onChange={(e) => textChange(e.target.value, setTextAB)}
          ></textarea>
        </div>

        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set A ∩ C</span>
          </label>
          <textarea
            id="text-b"
            value={textAC}
            ref={inputAC}
            className="w-1/2"
            disabled={!checkedItems.has(3)}
            onChange={(e) => textChange(e.target.value, setTextAC)}
          ></textarea>
        </div>

        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set B ∩ C</span>
          </label>
          <textarea
            id="text-b"
            value={textBC}
            ref={inputBC}
            className="w-1/2"
            disabled={!checkedItems.has(3)}
            onChange={(e) => textChange(e.target.value, setTextBC)}
          ></textarea>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">Set A ∩ B ∩ C</span>
          </label>
          <textarea
            id="text-b"
            value={textABC}
            ref={inputABC}
            className="w-1/2"
            disabled={!checkedItems.has(3)}
            onChange={(e) => textChange(e.target.value, setTextABC)}
          ></textarea>
        </div>
        <div className="flex justify-center items-center w-auto p-2">
          <label className="w-1/2">
            <span className="font-bold">
              Set A<sup>c</sup> ∩ B<sup>c</sup>{" "}
              {checkedItems.has(3) && (
                <span>
                  ∩ C<sup>c</sup>
                </span>
              )}
            </span>
          </label>
          <textarea
            id="text-b"
            value={textComp}
            className="w-1/2"
            onChange={(e) => textChange(e.target.value, setTextComp)}
          ></textarea>
        </div>
      </div>
      {/* ------------------------- */}
      <div className="flex justify-center items-center font-bold pt-4 text-xl">
        {title}
      </div>
      <div className="p-4">
        <div className="flex-row justify-center items-center mb-4 border-2 border-gray-300 rounded-3xl">
          {!refrash && (
            <div
              id="venn"
              className="justify-center items-center "
              style={{ textAlign: "center" }}
            />
          )}
          <div className="flex justify-center items-center mb-4">
            {textComp}
          </div>
        </div>
      </div>
      <div
        id="venn-title"
        className="bg-gray-600 p-4 text-gray-300 text-center justify-center items-center text-xs"
      >
        <a
          href="https://twitter.com/HamsterSyria"
          target="_blank"
          onClick={() => {
            (window as any).gtag("event", "About_us", {
              event_label: "About_us",
            });
          }}
        >
          About us
        </a>
      </div>
    </div>
  );
}

export default App;
